import { cn } from '@rouvydev/web-components/utils'
import { useEffect, useState } from 'react'
import { Cols } from '~/components/cols'
import { PlayButton } from '~/components/play-button.tsx'
import { ReactPlayer } from '~/components/react-player.client.tsx'
import { Spinner } from '~/components/spinner.tsx'
import { type YoutubeVideoFragment } from '~/model/api.ts'

export function prepareYoutubeProps(data: YoutubeVideoFragment) {
  return {
    id: data.id,
    title: data.youTubeTitle,
    caption: data.caption,
    cover: data.cover,
    embedCode: data.embedCode,
    anchor: data.youTubeAnchor,
    videoUrl: data.videoUrl,
  }
}

type Props = ReturnType<typeof prepareYoutubeProps>

export function Youtube(props: Props) {
  const { title, embedCode, caption, anchor, videoUrl } = props
  const [renderPlayer, setRenderPlayer] = useState(false)
  const [showPlayButton, setShowPlayButton] = useState(false)
  const [playing, setPlaying] = useState(false)
  const url = embedCode
    ? `https://www.youtube.com/watch?v=${embedCode}`
    : videoUrl
  const bgImageUrl = props.cover?.data?.attributes?.url

  useEffect(() => {
    setRenderPlayer(true)
  }, [])

  function onReady() {
    setShowPlayButton(true)
  }

  function onPlay() {
    setPlaying(true)
  }

  return (
    url && (
      <div id={anchor ?? undefined}>
        <div className="py-20">
          <div className="container">
            <Cols size="10">
              <div className="flex flex-col items-center">
                <h3 className="mb-10 font-display text-body-32 font-extrabold uppercase italic text-text-default md:text-display-40 xl:text-display-48">
                  {title}
                </h3>

                <div className="relative aspect-video w-full">
                  {renderPlayer && (
                    <div
                      className={cn(
                        'absolute z-20 h-full w-full opacity-0 transition-opacity duration-500',
                        !bgImageUrl && showPlayButton && 'opacity-100',
                        bgImageUrl && playing && 'opacity-100',
                      )}
                    >
                      <ReactPlayer
                        url={url}
                        height="100%"
                        width="100%"
                        onReady={onReady}
                        playing={playing}
                        controls={true}
                      />
                    </div>
                  )}
                  <div
                    className={cn(
                      'z-30 grid h-full w-full place-items-center bg-cover',
                      playing ? 'hidden' : 'absolute',
                      !bgImageUrl && showPlayButton && 'hidden',
                    )}
                    style={{
                      backgroundImage: bgImageUrl
                        ? `url(${bgImageUrl})`
                        : undefined,
                    }}
                  >
                    {showPlayButton ? (
                      bgImageUrl ? (
                        <PlayButton onClick={onPlay} />
                      ) : null
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
                <p className="mt-6 text-body-16 font-bold text-text-muted">
                  {caption}
                </p>
              </div>
            </Cols>
          </div>
        </div>
      </div>
    )
  )
}

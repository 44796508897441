import {
  RouteCard as SharedRouteCard,
  RouteVideoQuality,
} from '@rouvydev/web-components/cards'
import { useTranslation } from 'react-i18next'
import { ThumborUrlBuilder } from 'thumbor-url-builder-ts'
import { ExpandCard } from '~/components/collections/expand-card.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { type RoutesPageListItemFragment } from '~/model/api.ts'
import { formatDuration } from '~/utils/date.ts'
import {
  convertMetersToKm,
  useUnitConversion,
} from '~/utils/unit-conversions.ts'

const thumbor = new ThumborUrlBuilder(ENV.THUMBOR_SECURITY, ENV.THUMBOR_URL)

export function RouteCard(
  props: RoutesPageListItemFragment & {
    expand?: boolean
    className?: string
  },
) {
  const { t } = useTranslation(['collections'])
  const { convertDistance, convertElevation } = useUnitConversion()

  const url = `${ENV.RIDERS_PORTAL_URL}/route/${props.id}`
  const videoQuality =
    props.maxVideoQuality === 1440
      ? RouteVideoQuality._2K
      : RouteVideoQuality.HD

  const imageUrl =
    props.videoPreview.thumbnailHiRes ?? props.videoPreview.thumbnailClear

  const image = imageUrl
    ? thumbor.setImagePath(encodeURIComponent(imageUrl)).buildUrl()
    : undefined

  return (
    <ExpandCard
      card={
        <SharedRouteCard
          title={props.name}
          rating={props.rating}
          link={<NormalizedLink to={url} />}
          completed={false}
          distance={convertDistance(convertMetersToKm(props.distanceInMeters))}
          elevation={convertElevation(props.elevationInMeters)}
          estimatedTime={formatDuration(props.estimatedTime)}
          coins={props.coinsForCompletion}
          supportsAugmentedReality={props.supportsAugmentedReality}
          stravaLiveSegmentsCount={props.stravaLiveSegmentsCount}
          onlineCount={props.onlineCount ?? 0}
          strings={{
            completed: t('completed'),
            recommended: t('recommended'),
          }}
          image={image}
          maxVideoQuality={videoQuality}
          routeGeometry={props.geometry}
          useFixedSize
        />
      }
      link={url}
      expand={props.expand === true}
      expandedTitle={props.name}
      expandedDescription={props.description ?? undefined}
      className={props.className}
    />
  )
}

import { buttonVariants } from '@rouvydev/web-components/basics'
import { cn } from '@rouvydev/web-components/utils'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '~/components/carousel.tsx'
import { Image } from '~/components/image.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { type StepsFragment } from '~/model/api.ts'

export function prepareStepsProps(data: StepsFragment) {
  return {
    id: data.id,
    title: data.stepsTitle,
    button: {
      text: data.stepsButton?.text,
      link: data.stepsButton?.link,
      type: data.stepsButton?.buttonType,
      ctaEvent: data.stepsButton?.ctaEvent,
      style: data.stepsButton?.buttonStyle,
    },
    steps: data.step?.map((step) => ({
      title: step?.title,
      description: step?.description,
      image: step?.image,
      alternativeImage: step?.alternativeImage,
    })),
    anchor: data.stepsAnchor,
    disableNumbering: data.disableNumbering,
  }
}

type Props = ReturnType<typeof prepareStepsProps>

export function Steps(props: Props) {
  const { title, button, steps } = props
  return (
    <div id={props?.anchor ?? undefined}>
      <div className="py-20">
        <div className="container overflow-clip">
          <h3 className="mb-8 text-center font-display text-body-32 font-extrabold uppercase italic text-text-default md:text-display-40 xl:text-display-48">
            {title}
          </h3>
          {steps?.length && (
            <>
              <div className="hidden md:block">
                <Carousel
                  opts={{
                    dragFree: true,
                  }}
                >
                  <CarouselContent
                    className={cn(steps.length < 3 && 'justify-center')}
                  >
                    {steps.map((step, index) => (
                      <CarouselItem
                        key={index}
                        className={cn('basis-auto', index === 0 && 'pl-0')}
                      >
                        <article
                          key={index}
                          className="w-[416px]"
                          id={
                            props?.anchor
                              ? `${props.anchor}_${index + 1}`
                              : undefined
                          }
                        >
                          {step.image?.data?.attributes?.url && (
                            <div className="mx-auto flex items-center justify-center p-6 lg:p-4">
                              <Image
                                src={step.image.data.attributes.url}
                                alt={
                                  step.image?.data.attributes.alternativeText ??
                                  ''
                                }
                                width={step.image?.data.attributes.width ?? 200}
                                height={
                                  step.image?.data.attributes.height ?? 200
                                }
                                formats={step.image?.data.attributes.formats}
                                className="aspect-square h-[200px] object-contain"
                              />
                            </div>
                          )}
                          <div className="mx-auto max-w-md space-y-4">
                            <div className="relative">
                              <span
                                className={cn(
                                  'absolute left-52 top-1/2 h-0.5 w-[430px] bg-global-deep-indigo-700',
                                  index === steps.length - 1 && 'hidden',
                                )}
                              />
                              {props.disableNumbering === true ? (
                                <div className="relative z-10 mx-auto size-6 rounded-full bg-background-subtle"></div>
                              ) : (
                                <div className="relative z-10 mx-auto grid size-12 place-items-center rounded-full bg-background-subtle text-body-16 text-text-default xl:text-body-20">
                                  {index + 1}
                                </div>
                              )}
                            </div>
                            <h4 className="mx-auto max-w-[320px] text-center text-body-16 font-semibold text-text-default xl:text-body-20">
                              {step.title}
                            </h4>
                            <p className="mx-auto max-w-[320px] text-center text-body-12 font-semibold text-text-muted xl:text-body-16">
                              {step.description}
                            </p>
                          </div>
                        </article>
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                  {steps.length > 2 && (
                    <>
                      <CarouselPrevious />
                      <CarouselNext />
                    </>
                  )}
                </Carousel>
              </div>
              <div className="grid grid-cols-1 gap-y-10 md:hidden">
                {steps.map((step, index) => (
                  <article
                    key={index}
                    id={
                      props?.anchor ? `${props.anchor}_${index + 1}` : undefined
                    }
                  >
                    {step.image?.data?.attributes?.url && (
                      <div className="mx-auto flex items-center justify-center p-6 lg:p-4">
                        <Image
                          src={step.image.data.attributes.url}
                          alt={
                            step.image?.data.attributes.alternativeText ?? ''
                          }
                          width={step.image?.data.attributes.width ?? 200}
                          height={step.image?.data.attributes.height ?? 200}
                          formats={step.image?.data.attributes.formats}
                          className="aspect-square h-[200px] object-contain"
                        />
                      </div>
                    )}
                    <div className="mx-auto max-w-md space-y-4">
                      <div className="relative">
                        <span
                          className={cn(
                            'absolute -left-1/2 top-1/2 h-0.5 w-[200%] bg-global-deep-indigo-700',
                            index === 0 && 'left-1/2',
                            index === steps.length - 1 &&
                              'left-[unset] right-1/2',
                            steps.length === 1 && 'hidden',
                          )}
                        />
                        {props.disableNumbering === true ? (
                          <div className="relative z-10 mx-auto size-6 rounded-full bg-background-subtle"></div>
                        ) : (
                          <div className="relative z-10 mx-auto grid size-12 place-items-center rounded-full bg-background-subtle text-body-16 text-text-default xl:text-body-20">
                            {index + 1}
                          </div>
                        )}
                      </div>
                      <h4 className="text-center text-body-16 font-semibold text-text-default xl:text-body-20">
                        {step.title}
                      </h4>
                      <p className="text-center text-body-12 font-semibold text-text-muted xl:text-body-16">
                        {step.description}
                      </p>
                    </div>
                  </article>
                ))}
              </div>
            </>
          )}

          {button?.link && (
            <p className="mt-16 text-center">
              <NormalizedLink
                className={cn(
                  buttonVariants({
                    variant: button.style ?? 'primary',
                  }),
                  button.style,
                )}
                to={button.link}
              >
                {button.text}
              </NormalizedLink>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

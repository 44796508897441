import { Checklist as ChecklistBlock } from '@rouvydev/web-components/cms-blocks'
import { type ChecklistFragment } from '~/model/api.ts'

export function prepareChecklistProps(data: ChecklistFragment) {
  return {
    id: data.id,
    title: data.checklistTitle,
    items: data.checklistItems
      .split('\n')
      .map((item) => item.trim())
      .filter((item) => item.length > 0),
    anchor: data.checklistAnchor,
    vertical: data.vertical,
  }
}

type Props = ReturnType<typeof prepareChecklistProps>

export function Checklist(props: Props) {
  const { title, items, vertical } = props

  return (
    <div id={props?.anchor ?? undefined}>
      <div className="container">
        <ChecklistBlock
          items={items}
          title={title}
          vertical={vertical === true}
        />
      </div>
    </div>
  )
}

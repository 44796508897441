import { type RenderableTreeNodes } from '@markdoc/markdoc'
import { buttonVariants } from '@rouvydev/web-components/basics'
import { PlansSelector as PlansSelectorBlock } from '@rouvydev/web-components/plans'
import { cn } from '@rouvydev/web-components/utils'
import { useTranslation } from 'react-i18next'
import { Article } from '~/components/article.tsx'
import { Markdown } from '~/components/markdown.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { type PlansSelectorFragment } from '~/model/api.ts'
import { priceFormatRaw } from '~/utils/priceFormat.ts'
import { type AllPlansResult, type Plan } from '~/utils/pricing.server.ts'

type TransformedPlansSelectorData = {
  plansData: AllPlansResult
  transformedTermsSection?: RenderableTreeNodes
} & PlansSelectorFragment

export function preparePlansSelectorProps(data: PlansSelectorFragment) {
  const { plansData, transformedTermsSection } =
    data as TransformedPlansSelectorData

  const plans = plansData?.plans?.map((plan: Plan) => ({
    id: plan.id,
    planId: plan.item_id,
    price: plan.price,
    currency_code: plan.currency_code,
    trialDays: plan.trial_period,
    billingPeriod: plan.period_unit,
  }))

  return {
    id: data.id,
    title: data.plansSelectorTitle,
    subtitle: data.plansSelectorSubtitle,
    anchor: data.plansSelectorAnchor,
    button: data.button,
    terms: transformedTermsSection,
    plans,
  }
}

type Props = ReturnType<typeof preparePlansSelectorProps>

/**
 * t('item_title_rouvy_single')
 * t('item_title_rouvy_duo')
 * t('item_title_rouvy_group')
 * t('item_accounts_rouvy_single')
 * t('item_accounts_rouvy_duo')
 * t('item_accounts_rouvy_group')
 */
export function PlansSelector(props: Props) {
  const { title, subtitle, button, plans, terms } = props
  const { t } = useTranslation('pricing')

  const items = plans?.map((plan) => ({
    id: plan.id,
    planId: plan.planId,
    billingPeriod: plan.billingPeriod,
    price:
      plan.billingPeriod === 'year'
        ? priceFormatRaw(plan.price / 12, plan.currency_code)
        : priceFormatRaw(plan.price, plan.currency_code),
    title: t(`item_title_${plan.planId}`),
    content: [
      t(`item_accounts_${plan.planId}`),
      t('item_trial_days', { trialDays: plan.trialDays }),
    ],
  }))

  return (
    <div id={props?.anchor ?? undefined}>
      <div className="container py-24">
        <div className="flex flex-col items-center gap-12 lg:gap-16">
          <div className="flex flex-col gap-6">
            <h4 className="text-center font-display text-body-32 font-extrabold uppercase italic text-text-default md:text-display-40 lg:text-display-48">
              {title}
            </h4>
            <span className="block-description text-center">{subtitle}</span>
          </div>
          <div className="-mb-12 -mt-10 w-full">
            <PlansSelectorBlock
              interactive={false}
              active={null}
              defaultPlanId=""
              periods={['year', 'month']}
              initialPeriod="year"
              items={items ?? []}
              strings={{
                period_year: t('period_year'),
                year_free_months_claim: t('year_free_months_claim'),
                period_month: t('period_month'),
                current_plan: t('current_plan'),
                billed_monthly: t('billed_monthly'),
                billed_yearly: t('billed_yearly'),
                per_month_short: t('per_month_short'),
              }}
            />
          </div>
          {button && (
            <NormalizedLink
              to={button.link}
              className={cn(
                buttonVariants({
                  variant: button.buttonStyle ?? 'primary',
                }),
                button.buttonStyle,
                'justify-center max-md:w-full',
              )}
            >
              {button.text}
            </NormalizedLink>
          )}
          {terms && (
            <Article className="text-center text-body-12 font-medium leading-normal prose-p:my-0 md:text-left">
              <Markdown content={terms} />
            </Article>
          )}
        </div>
      </div>
    </div>
  )
}

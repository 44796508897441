import { type ButtonProps, IconButton } from '@rouvydev/web-components/basics'
import { cn } from '@rouvydev/web-components/utils'
import * as React from 'react'
import { Icon } from '~/components/icon.tsx'

const PlayButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => {
    return (
      <IconButton
        ref={ref}
        icon={<Icon name="icon-play" />}
        className={cn(
          'size-16 justify-center bg-button-default-background-rest md:size-24 [&_svg]:size-8 md:[&_svg]:size-16',
          className,
        )}
        variant="secondary"
        {...props}
      />
    )
  },
)

PlayButton.displayName = 'PlayButton'

export { PlayButton }

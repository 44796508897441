import { Badge, TrainingPlansCard } from '@rouvydev/web-components/content'
import { useTranslation } from 'react-i18next'
import { ThumborUrlBuilder } from 'thumbor-url-builder-ts'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { type TrainingPlanOverviewFragment } from '~/model/api.ts'
import { averageWorkloadPerWeek, durationInWeeks } from '~/utils/training-plans'

const thumbor = new ThumborUrlBuilder(ENV.THUMBOR_SECURITY, ENV.THUMBOR_URL)

type Props = Pick<
  TrainingPlanOverviewFragment,
  | 'id'
  | 'name'
  | 'description'
  | 'backgroundImageUrl'
  | 'expectedDurationDays'
  | 'totalDurationSeconds'
  | 'coinsForCompletion'
  | 'trainingUnitsCount'
  | 'trainingPlanTags'
> & {
  showProgressBar?: boolean
}

export function TrainingPlanOverview({
  id,
  name,
  description,
  backgroundImageUrl,
  expectedDurationDays,
  totalDurationSeconds,
  coinsForCompletion,
  trainingUnitsCount,
  trainingPlanTags,
  showProgressBar,
}: Props) {
  const { t } = useTranslation(['collections'])

  const url = `${ENV.RIDERS_PORTAL_URL}/training-plans/${id}`

  const duration = durationInWeeks(expectedDurationDays)

  const averageWorkload = averageWorkloadPerWeek(
    totalDurationSeconds,
    expectedDurationDays,
  )

  const thumborImageUrl = thumbor
    .setImagePath(encodeURIComponent(backgroundImageUrl))
    .buildUrl()

  const badges =
    trainingPlanTags?.map((tag) => (
      <Badge key={tag.name} style={{ color: tag.color }}>
        {tag.name}
      </Badge>
    )) ?? []

  return (
    <TrainingPlansCard
      title={name}
      pictureUrl={thumborImageUrl}
      description={description}
      duration={`${duration} ${t('week', {
        count: duration,
      })}`}
      link={<NormalizedLink to={url} />}
      periodicLoad={`${averageWorkload}${t('hours_label')} / ${t('per_week')}`}
      badges={badges}
      coins={coinsForCompletion ? `+${coinsForCompletion}` : undefined}
      numberOfSegments={trainingUnitsCount}
      currentSegment={showProgressBar ? 0 : undefined}
    />
  )
}

import { type RenderableTreeNodes } from '@markdoc/markdoc'
import { buttonVariants } from '@rouvydev/web-components/basics'
import { cn } from '@rouvydev/web-components/utils'
import { useTranslation } from 'react-i18next'
import { Article } from '~/components/article.tsx'
import { Markdown } from '~/components/markdown.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { Separator } from '~/components/separator.tsx'
import { type PricingFragment } from '~/model/api.ts'
import { priceFormatRaw } from '~/utils/priceFormat.ts'
import { type PlanResult } from '~/utils/pricing.server.ts'

type TransformedPricing = {
  transformedContentText: RenderableTreeNodes
  transformedTermsSection?: RenderableTreeNodes
  planData: PlanResult
} & PricingFragment

export function preparePricingProps(data: PricingFragment) {
  return {
    title: data.pricingTitle,
    content: (data as TransformedPricing).transformedContentText,
    monthly: data.monthly,
    yearlyToMonths: data.yearlyToMonths,
    planData: (data as TransformedPricing).planData.plan,
    anchor: data.pricingAnchor,
    button: data.button,
    terms: (data as TransformedPricing).transformedTermsSection,
  }
}

type Props = ReturnType<typeof preparePricingProps>

// t("period_month")
// t("period_year")

export function Pricing(props: Props) {
  const { t } = useTranslation(['pricing'])

  return props.planData ? (
    <div className="py-20 md:py-24" id={props.anchor ?? undefined}>
      <div className="container">
        <div className="mx-auto flex w-full flex-col items-center gap-10 md:flex-row lg:w-2/3">
          <div className="md:w-2/3 lg:w-1/2">
            <div className="mx-auto flex h-[379px] w-[290px] flex-col items-center gap-8 bg-background-subtle px-8 pb-20 pt-12 text-center md:w-[336px]">
              <span className="text-uppercase-16 uppercase text-global-white">
                {t(`period_${props.planData.period_unit}`)}
              </span>
              <Separator className="h-[3px] w-12 bg-border-secondary" />
              <div className="flex flex-col gap-2">
                <span className="font-display text-display-60 font-extrabold uppercase italic text-non-contextual-jonquil-yellow-default">
                  {!props.monthly && props.yearlyToMonths
                    ? priceFormatRaw(
                        props.planData.price / 12,
                        props.planData.currency_code,
                      )
                    : priceFormatRaw(
                        props.planData.price,
                        props.planData.currency_code,
                      )}
                </span>
                <span className="text-body-20 text-global-white">
                  {(!props.monthly && props.yearlyToMonths) || props.monthly
                    ? t('per_month')
                    : t('per_year')}
                </span>
              </div>

              {!props.monthly && props.yearlyToMonths && (
                <span className="text-body-16 text-text-muted">
                  {priceFormatRaw(
                    props.planData.price,
                    props.planData.currency_code,
                  )}
                  &nbsp;
                  {t('per_year')}
                </span>
              )}
            </div>
          </div>
          <div className="flex w-full flex-col items-center gap-8 py-6 max-md:order-first md:w-2/3 md:items-start lg:w-1/2">
            <h4 className="text-center font-display text-body-32 font-extrabold uppercase italic text-text-default lg:text-left lg:text-display-48">
              {props.title}
            </h4>
            <div className="block-description text-center md:text-left">
              <Markdown content={props.content} />
            </div>
            {props.button?.link && (
              <NormalizedLink
                to={props.button.link}
                className={cn(
                  buttonVariants({
                    variant: props.button.buttonStyle ?? 'alternative',
                  }),
                  'justify-center max-md:w-full',
                )}
              >
                {props.button.text}
              </NormalizedLink>
            )}
            {props.terms && (
              <Article className="text-center text-body-12 font-medium leading-normal prose-p:my-0 md:text-left">
                <Markdown content={props.terms} />
              </Article>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null
}

import { type RenderableTreeNodes } from '@markdoc/markdoc'
import { buttonVariants } from '@rouvydev/web-components/basics'
import { cn } from '@rouvydev/web-components/utils'
import { useTranslation } from 'react-i18next'
import { ThumborUrlBuilder } from 'thumbor-url-builder-ts'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '~/components/carousel.tsx'
import { Markdown } from '~/components/markdown.tsx'
import { DynamicZoneSectionGradients } from '~/components/ui/section-gradients.tsx'
import {
  type ComponentSharedLinksCarouselItem,
  type LinksCarouselFragment,
} from '~/model/api.ts'

type TransformedLinksCarouselItem = {
  transformedDescription: RenderableTreeNodes
} & ComponentSharedLinksCarouselItem

export function prepareLinksCarouselProps(data: LinksCarouselFragment) {
  return {
    title: data.linksCarouselTitle,
    anchor: data.linksCarouselAnchor,
    links: data.items?.map((item) => {
      const transformedItem = item as TransformedLinksCarouselItem

      return {
        ...item,
        description: transformedItem.transformedDescription,
      }
    }),
    background: data.background,
  }
}

type Props = ReturnType<typeof prepareLinksCarouselProps>

export function LinksCarousel({ title, anchor, links, background }: Props) {
  const { t } = useTranslation()
  function bgImageUrl(url: string | undefined) {
    const thumbor = new ThumborUrlBuilder(ENV.THUMBOR_SECURITY, ENV.THUMBOR_URL)

    return url
      ? thumbor
          .setImagePath(encodeURIComponent(url))
          .resize(304, 296)
          .smartCrop(true)
          .buildUrl()
      : ''
  }

  const bg =
    background && background !== 'none'
      ? `bg-${background.replace('_', '-')}`
      : ''

  return (
    <div>
      <div
        className={cn(bg, 'relative overflow-hidden bg-cover bg-no-repeat')}
        id={anchor ?? undefined}
      >
        <DynamicZoneSectionGradients />
        <div className="py-20 md:py-24">
          <div className="container pb-24">
            <h3 className="text-center font-display text-body-32 font-extrabold uppercase italic text-text-default md:text-display-40 xl:text-display-48">
              {title}
            </h3>
          </div>
          {links?.length ? (
            <Carousel
              className="px-4"
              opts={{
                dragFree: true,
              }}
            >
              <CarouselContent>
                {links?.map((item, index) => (
                  <CarouselItem
                    key={index}
                    className={cn('basis-auto pl-8', index === 0 && 'pl-0')}
                  >
                    <article className="flex h-[32rem] w-[16.625rem] flex-col gap-4 bg-global-dark-ink-blue-200 md:h-[39.25rem] md:w-[26rem] md:gap-5">
                      <div
                        className="flex h-[50%] flex-col justify-end bg-cover bg-top px-5 pt-5 text-center md:h-[60%] md:px-8 md:pt-8"
                        style={{
                          background: item.image?.data?.attributes?.url
                            ? `linear-gradient(0deg, #110728 0%, rgba(17, 7, 40, 0.00) 71.59%), url(${bgImageUrl(
                                item.image?.data?.attributes?.url,
                              )}) lightgray 50% / cover no-repeat`
                            : '',
                        }}
                      >
                        <h4 className="block-subtitle line-clamp-2 font-display text-[1.75rem] font-extrabold leading-7 md:text-[2rem] md:leading-8">
                          {item?.title}
                        </h4>
                      </div>
                      <div className="flex h-[50%] flex-col justify-between px-5 pb-5 md:h-[40%] md:px-8 md:pb-8">
                        <div className="block-description line-clamp-6 text-center text-[.875rem] font-semibold md:text-[1rem] md:font-medium">
                          <Markdown content={item.description} />
                        </div>
                        <div className="text-center">
                          {item?.url && (
                            <a
                              className={buttonVariants({
                                variant: 'alternative',
                                size: 'small',
                              })}
                              href={item.url}
                            >
                              <span className="line-clamp-2">
                                {item.label ? item.label : t('detail')}
                              </span>
                            </a>
                          )}
                        </div>
                      </div>
                    </article>
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselPrevious />
              <CarouselNext />
            </Carousel>
          ) : null}
        </div>
      </div>
    </div>
  )
}

import { buttonVariants } from '@rouvydev/web-components/basics'
import { cn } from '@rouvydev/web-components/utils'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '~/components/carousel.tsx'
import { ChallengeCard } from '~/components/collections/challenge-card.tsx'
import { EventInfoCard } from '~/components/collections/event-info-card'
import { JokerCard } from '~/components/collections/joker-card'
import { RouteCard } from '~/components/collections/route-card.tsx'
import { TrainingPlanOverview } from '~/components/collections/training-plan-card'
import { WorkoutCard } from '~/components/collections/workout-card.tsx'
import { Gradient3 } from '~/components/gradients.tsx'
import { Image } from '~/components/image.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import {
  type EventInfo,
  type SpotlightFragment,
  type SpotlightsFragment,
} from '~/model/api.ts'

function prepareSpotlightsProps(data: SpotlightsFragment) {
  return {
    anchor: data.spotlightsAnchorId,
    ...data.spotlight?.data?.attributes,
  }
}

type Props = ReturnType<typeof prepareSpotlightsProps>

type SpotlightIntroProps = Pick<
  Props,
  'title' | 'subtitle' | 'description' | 'button'
> & {
  className?: string
}

function SpotlightIntro({
  title,
  subtitle,
  description,
  button,
  className,
}: SpotlightIntroProps) {
  return (
    <div className={cn('z-10 max-lg:px-4 md:w-[640px]', className)}>
      <div className="flex h-[480px] w-full flex-col gap-4 text-center md:h-[400px] md:w-[416px] md:justify-center md:gap-6 md:text-left">
        {subtitle && (
          <p className="text-body-16 font-bold text-global-white md:text-body-20">
            {subtitle}
          </p>
        )}
        <h2 className="block-heading font-display text-[2.25rem] italic leading-10 text-global-white md:text-display-48 md:leading-[4rem]">
          {title}
        </h2>
        <p className="block-description line-clamp-3 text-body-16 font-medium md:text-body-20">
          {description}
        </p>
        {button && (
          <div>
            <NormalizedLink
              to={button.link}
              className={cn(
                buttonVariants({ variant: 'alternative' }),
                'w-fit',
              )}
            >
              {button.text}
            </NormalizedLink>
          </div>
        )}
      </div>
    </div>
  )
}

function Spotlights({
  backgroundImage,
  subtitle,
  title,
  spotlights,
  button,
  description,
  anchor,
}: Props) {
  function renderItem(item: SpotlightFragment) {
    if (item?.route) {
      return <RouteCard key={item.id} {...item.route} />
    }

    if (item?.eventInfo && item?.eventInfo?.data?.attributes) {
      const eventInfo = item?.eventInfo?.data?.attributes
      return <EventInfoCard key={eventInfo?.id} {...(eventInfo as EventInfo)} />
    }
    if (
      item?.challenge &&
      item?.challenge?.data?.attributes?.title &&
      item?.challenge?.data?.attributes?.logoOriginal
    ) {
      const challenge = item?.challenge?.data?.attributes
      return <ChallengeCard key={item.id} {...(challenge as any)} />
    }
    if (item?.workout) {
      return <WorkoutCard key={item.id} {...item.workout} />
    }
    if (item?.trainingPlan) {
      return (
        <TrainingPlanOverview
          key={item.id}
          {...item.trainingPlan}
          showProgressBar
        />
      )
    }
    if (item?.card) {
      return (
        <JokerCard
          title={item.card.title}
          description={item.card.description}
          url={item.card.url}
          cover={item.card.cover.data?.attributes?.url}
        />
      )
    }

    return null
  }

  return (
    <div className="relative py-20">
      {backgroundImage && (
        <Image
          className="spotlight-bg-mask-sm absolute -z-10 max-md:top-1/4 md:hidden"
          src={backgroundImage?.data?.attributes?.url ?? ''}
          alt={backgroundImage?.data?.attributes?.alternativeText ?? ''}
          formats={backgroundImage?.data?.attributes?.formats}
          height={900}
          width={900}
        />
      )}
      <Gradient3 className="-z-20" />
      <SpotlightIntro
        className="flex md:hidden"
        title={title}
        subtitle={subtitle}
        description={description}
        button={button}
      />
      <Carousel
        className="px-4"
        id={anchor ?? undefined}
        opts={{
          dragFree: true,
        }}
      >
        <CarouselContent>
          <CarouselItem
            key={0}
            className="relative hidden basis-auto pl-0 md:flex"
          >
            <SpotlightIntro
              title={title}
              subtitle={subtitle}
              description={description}
              button={button}
            />
            <Image
              className="spotlight-bg-mask absolute -right-14 -z-10 -ml-[460px] max-h-[400px]"
              src={backgroundImage?.data?.attributes?.url ?? ''}
              alt={backgroundImage?.data?.attributes?.alternativeText ?? ''}
              formats={backgroundImage?.data?.attributes?.formats}
              height={900}
              width={900}
            />
          </CarouselItem>

          {spotlights?.map(
            (item, index) =>
              item && (
                <CarouselItem
                  key={++index}
                  className={cn('basis-auto pl-8', index === 0 && 'pl-0')}
                >
                  {renderItem(item)}
                </CarouselItem>
              ),
          )}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  )
}

export { prepareSpotlightsProps, Spotlights }

import { type StripeFragment } from '~/model/api.ts'

function prepareStripeProps(data: StripeFragment) {
  return {
    anchor: data.stripeAnchorId ?? undefined,
    title: data.stripeTitle,
    secondTitle: data.titleSecondLine,
  }
}

type Props = ReturnType<typeof prepareStripeProps>

function Stripe({ anchor, title, secondTitle }: Props) {
  const textClassName =
    'font-display text-body-32 uppercase italic leading-tight text-text-default lg:text-display-48 leading-8 lg:leading-[3rem]'
  return (
    <div>
      <div
        id={anchor ?? undefined}
        className="bg-global-dark-ink-blue-500 py-20 text-center"
      >
        <div className="container">
          <h2 className={textClassName}>{title}</h2>
          {secondTitle && <h2 className={textClassName}>{secondTitle}</h2>}
        </div>
      </div>
    </div>
  )
}

export { prepareStripeProps, Stripe }

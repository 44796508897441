import { conform, useForm } from '@conform-to/react'
import { getFieldsetConstraint, parse } from '@conform-to/zod'
import { Button } from '@rouvydev/web-components/basics'
import { useTranslation } from 'react-i18next'
import { Form, useActionData } from 'react-router'
import { z } from 'zod'
import { Alert } from '~/components/alert.tsx'
import { Cols } from '~/components/cols.tsx'
import { ErrorList } from '~/components/errors-list.tsx'
import { Icon } from '~/components/icon.tsx'
import { Input } from '~/components/input.tsx'
import { type RegisterCtaFragment } from '~/model/api.ts'
import { type action } from '~/routes/resources.register.ts'
import { trackEvent } from '~/utils/gtm.client.ts'
import { useIsPending } from '~/utils/misc.ts'
import { EmailSchema } from '~/utils/schema.ts'

export const RegisterSchema = z.object({
  email: EmailSchema,
  linkAfterEmailSubmit: z.string().nullish(),
})

export function prepareRegisterCtaProps({
  id,
  registerCtaTitle,
  registerCtaDescription,
  registerCtaCampaignId,
  registerCtaAnchor,
  linkAfterEmailSubmit,
}: RegisterCtaFragment) {
  return {
    id,
    title: registerCtaTitle,
    description: registerCtaDescription,
    campaignId: registerCtaCampaignId,
    anchor: registerCtaAnchor,
    linkAfterEmailSubmit,
  }
}

type Props = ReturnType<typeof prepareRegisterCtaProps>

const devices = [
  {
    label: 'Windows, Mac',
    icon: 'notebook',
  },
  {
    label: 'iOS, Android',
    icon: 'android',
  },
  {
    label: 'iPad',
    icon: 'ipad',
  },
  {
    label: 'Apple TV',
    icon: 'apple-tv',
  },
] as const

export function Gradient() {
  return (
    <div>
      <div
        className="absolute inset-0"
        style={{
          background:
            'radial-gradient(121.07% 300% at -20% 217.45%, rgba(111, 16, 231, 0.40) 0%, rgba(124, 16, 231, 0.00) 100%)',
        }}
      />
      <div
        className="absolute inset-0"
        style={{
          background:
            'radial-gradient(170.79% 240.66% at 180% -50.97%, rgba(252, 205, 0, 0.40) 0%, rgba(216, 156, 0, 0.00) 100%)',
        }}
      />
    </div>
  )
}

export function RegisterCta(props: Props) {
  const { t } = useTranslation()
  const { title, description, campaignId, linkAfterEmailSubmit } = props
  const actionData = useActionData<typeof action>()
  const pending = useIsPending()

  const [form, fields] = useForm({
    id: 'register-cta-form',
    constraint: getFieldsetConstraint(RegisterSchema),
    defaultValue: { email: '' },
    lastSubmission: actionData?.submission,
    onValidate({ formData }) {
      return parse(formData, { schema: RegisterSchema })
    },
    onSubmit(_, { formData }) {
      trackEvent('email_captured', {
        campaignId: campaignId,
        formType: 'lead',
        capturedEmail: formData.get('email'),
      })
    },
    shouldRevalidate: 'onBlur',
  })

  return (
    <div id={props?.anchor ?? undefined}>
      <div className="py-20 md:py-32">
        <div className="container">
          <div className="relative flex flex-col items-center px-4 py-20">
            <Gradient />
            <Cols size="8" className="relative">
              <div className="z-50 flex flex-col gap-4">
                <h3 className="text-center font-display text-body-32 font-extrabold uppercase italic text-text-default md:text-display-40 lg:text-display-48">
                  {title}
                </h3>
                <p className="text-center text-body-16 font-semibold text-text-muted xl:text-body-20">
                  {description}
                </p>
                {actionData?.status === 'error' && (
                  <Alert
                    variant="warning"
                    closeable={false}
                    message={t('registration_subscription_error')}
                  />
                )}
                <Form
                  {...form.props}
                  method="POST"
                  action="/resources/register"
                >
                  <div className="flex flex-col gap-4 md:flex-row md:items-center">
                    <Input
                      {...conform.input(fields.email)}
                      className="flex-1"
                      placeholder={t('register_email_placeholder')}
                    />
                    <Button
                      type="submit"
                      className="w-full justify-center md:w-auto"
                      variant="primary"
                    >
                      {pending
                        ? t('register_button_pending')
                        : t('register_button')}
                    </Button>
                  </div>
                  <ErrorList
                    errors={
                      fields.email.errors?.length
                        ? fields.email.errors
                        : ['\u00A0']
                    }
                  />
                  <input
                    type="hidden"
                    name="campaignId"
                    value={campaignId ?? undefined}
                  />
                  <input
                    type="hidden"
                    name="linkAfterEmailSubmit"
                    value={linkAfterEmailSubmit ?? undefined}
                  />
                </Form>
              </div>
            </Cols>
            <div className="mt-10 grid grid-cols-2 place-items-center gap-x-12 gap-y-6 md:grid-cols-4 md:gap-x-20 md:gap-y-0">
              {devices.map((device, index) => (
                <div
                  key={index}
                  className="flex h-[120px] w-[120px] flex-col items-center text-center text-text-muted"
                >
                  <Icon
                    className="h-[80px] w-[80px] md:h-[120px] md:w-[120px]"
                    name={device.icon}
                  />
                  <span className="font-bold">{device.label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

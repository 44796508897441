import { buttonVariants } from '@rouvydev/web-components/basics'
import { Image } from '~/components/image.tsx'
import { type PrizesFragment } from '~/model/api.ts'

export function preparePrizesCtaProps({
  id,
  prizesTitle,
  prizesAnchor,
  Prizes,
  Button,
}: PrizesFragment) {
  return {
    id,
    title: prizesTitle,
    anchor: prizesAnchor,
    button: {
      text: Button?.text,
      type: Button?.buttonType,
      style: Button?.buttonStyle,
      ctaEvent: Button?.ctaEvent,
      link: Button?.link,
    },
    prizes: Prizes,
  }
}

type Props = ReturnType<typeof preparePrizesCtaProps>

export function Prizes(props: Props) {
  const { title, button, prizes } = props
  return (
    <div id={props?.anchor ?? undefined}>
      <div className="py-20 md:py-24">
        <div className="container">
          {title && (
            <h3 className="font-display text-body-32 font-extrabold uppercase italic text-text-default md:text-display-40 xl:text-display-48">
              {title}
            </h3>
          )}
          {prizes?.length ? (
            <div className="hide-scrollbar flex items-center gap-x-8 overflow-x-auto py-20">
              {prizes.map((prize) => (
                <div
                  className="h-[400px] w-[272px] shrink-0 bg-background-default md:w-[288px] xl:h-[518px] xl:w-[416px]"
                  key={prize?.title}
                >
                  {prize?.image?.data?.attributes?.url && (
                    <div className="h-[216px] overflow-hidden xl:h-[330px]">
                      <Image
                        className="block h-auto max-h-full w-auto min-w-full object-contain"
                        src={prize?.image.data?.attributes?.url}
                        alt={
                          prize?.image?.data.attributes.alternativeText ?? ''
                        }
                        formats={prize?.image?.data.attributes.formats}
                      />
                    </div>
                  )}
                  <div className="flex flex-col gap-4 p-[20px] xl:p-8">
                    <h4 className="line-clamp-1 text-center font-display text-display-28 font-extrabold uppercase italic text-text-default md:text-body-32">
                      {prize?.title}
                    </h4>
                    <p className="line-clamp-3 text-center text-body-16 font-semibold text-text-muted">
                      {prize?.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {button?.link && button?.text && (
            <div className="text-center">
              <a
                className={buttonVariants({
                  variant: button.style ?? 'alternative',
                })}
                href={button.link}
              >
                {button.text}
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

import { cn } from '@rouvydev/web-components/utils'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
  id,
  errors,
  className,
}: {
  errors?: ListOfErrors
  id?: string
  className?: string
}) {
  const errorsToRender = errors?.filter(Boolean)
  if (!errorsToRender?.length) return null
  return (
    <ul id={id} className={cn('py-1', className)}>
      {errorsToRender.map((e) => (
        <li key={e} className="py-1 text-body-14 text-text-negative">
          {e}
        </li>
      ))}
    </ul>
  )
}

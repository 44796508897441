import { type RenderableTreeNodes } from '@markdoc/markdoc'
import { cn } from '@rouvydev/web-components/utils'
import { Article } from '~/components/article'
import { Cols } from '~/components/cols'
import { Image } from '~/components/image'
import { Markdown as BaseMarkdown } from '~/components/markdown'
import { type TwoColumnsContentFragment } from '~/model/api'

type TransformedTwoColumnsContent = {
  transformedText: RenderableTreeNodes
} & TwoColumnsContentFragment

export function prepareTwoColumnsContentProps(data: TwoColumnsContentFragment) {
  const transformedObject = data as TransformedTwoColumnsContent

  return {
    title: data.twoColumnsContentTitle,
    description: data.twoColumnsContentDescription,
    text: transformedObject.transformedText,
    image: data.twoColumnsContentImage,
  }
}

type Props = ReturnType<typeof prepareTwoColumnsContentProps>

function Markdown({ text }: { text: RenderableTreeNodes }) {
  return (
    <div className="prose-p:text-body-14 prose-p:md:text-body-16 [&_article>p:first-child]:mt-0">
      <BaseMarkdown content={text} />
    </div>
  )
}

function Title({ title, className }: { title: string; className?: string }) {
  return (
    <h2
      className={cn(
        'pb-6 font-display text-display-32 uppercase italic text-text-default md:text-display-40 lg:pb-10 lg:text-display-48',
        className,
      )}
    >
      {title}
    </h2>
  )
}

function Description({
  description,
  className,
}: {
  description: string
  className?: string
}) {
  return (
    <p
      className={cn(
        'mb-0 pb-6 text-body-16 !text-text-muted md:text-body-18 lg:pb-10 lg:text-body-20',
        className,
      )}
    >
      {description}
    </p>
  )
}
export function TwoColumnsContent(props: Props) {
  const { title, description, text, image } = props

  const imageUrl = image?.data?.attributes?.url

  return (
    <div className="container">
      <Cols size="12" className="py-12 md:py-16 lg:py-20">
        {imageUrl ? (
          <div className="flex flex-col items-center gap-8 md:flex-row md:gap-12 lg:gap-16">
            <div className="md:w-[50%]">
              {title && <Title title={title} />}
              {description && <Description description={description} />}
              <Article className="prose-p:text-text-default">
                <Markdown text={text} />
              </Article>
            </div>
            <div className="md:w-[50%]">
              <Image src={imageUrl} className="h-full w-full object-cover" />
            </div>
          </div>
        ) : (
          <>
            {title && <Title title={title} className="md:max-w-[50%]" />}
            <div className="columns-1 gap-8 md:columns-2 md:gap-12 lg:gap-16">
              {description && <Description description={description} />}
              <Article className=" prose-p:text-text-default ">
                <Markdown text={text} />
              </Article>
            </div>
          </>
        )}
      </Cols>
    </div>
  )
}

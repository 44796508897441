import { cn } from '@rouvydev/web-components/utils'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '~/components/carousel.tsx'
import { Image } from '~/components/image.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { type ContentTeaserFragment } from '~/model/api.ts'

export function prepareContentTeaserProps(data: ContentTeaserFragment) {
  return {
    title: data.teaserTitle,
    description: data.teaserDescription,
    bgImage: data.backgroundImage,
    bgImageResponsive: data.backgroundImageResponsive,
    cards: data.cards,
    size: data.size,
    anchor: data.teaserAnchor,
    logo: data.logo,
    logoResponsive: data.logoResponsive,
  }
}

type Props = ReturnType<typeof prepareContentTeaserProps>

export function ContentTeaser(props: Props) {
  const bgImageUrl = props.bgImage?.data?.attributes?.url
  const responsiveBgImageUrl =
    props.bgImageResponsive?.data?.attributes?.url || bgImageUrl

  return (
    <div
      className="overflow-hidden pb-20 pt-10 md:py-24"
      id={props.anchor ?? undefined}
    >
      <div className="container relative">
        <div className="absolute top-80 -ml-4 flex h-full w-full justify-center sm:top-36 md:-right-40 md:-top-24 lg:right-[-300px]">
          {bgImageUrl && (
            <Image
              src={bgImageUrl ?? ''}
              role="presentation"
              alt={props.bgImage?.data?.attributes?.alternativeText ?? ''}
              className={cn(
                'teaser-mask hidden h-[743px] w-[1056px] max-w-none object-cover md:block',
              )}
            />
          )}
          {responsiveBgImageUrl && (
            <Image
              src={responsiveBgImageUrl ?? ''}
              role="presentation"
              alt={
                props.bgImageResponsive?.data?.attributes?.alternativeText ?? ''
              }
              className={cn(
                'teaser-mask block h-auto w-[600px] max-w-none object-cover sm:w-[800px] md:hidden',
              )}
            />
          )}
        </div>
        <div className="relative grid grid-cols-1 pt-10 text-center md:grid-cols-6 md:pb-32 md:pt-32 md:text-left lg:grid-cols-5">
          <div className="col-span-1 md:col-span-3 lg:col-span-2">
            <h3 className="mb-4 font-display text-display-40 uppercase italic text-global-white md:text-display-48 lg:mb-6">
              {props.title}
            </h3>
            <p className="text-center text-body-16 font-semibold text-text-muted md:text-left md:text-body-18 lg:text-body-20">
              {props.description}
            </p>
          </div>
          <div className="relative col-span-1 flex h-80 justify-center md:col-span-3 lg:col-span-3">
            <div className="absolute bottom-0">
              <Image
                src={props.logo?.data?.attributes?.url ?? ''}
                role="presentation"
                alt={props.logo?.data?.attributes?.alternativeText ?? ''}
                className="mb-3 hidden max-h-40 md:inline-block"
              />
              <Image
                src={props.logoResponsive?.data?.attributes?.url ?? ''}
                role="presentation"
                alt={
                  props.logoResponsive?.data?.attributes?.alternativeText ?? ''
                }
                className="mb-3 inline-block max-h-32 md:hidden"
              />
            </div>
          </div>
        </div>
        <Carousel
          opts={{
            dragFree: true,
            slidesToScroll: 'auto',
          }}
        >
          <CarouselContent>
            {props.cards &&
              props.cards.length > 0 &&
              props.cards.map((card, index) =>
                card?.url ? (
                  <CarouselItem
                    key={index}
                    className={cn('basis-auto pl-4', index === 0 && 'pl-0')}
                  >
                    <NormalizedLink to={card.url}>
                      <TeaserCard
                        title={card?.title ?? ''}
                        description={card?.description ?? ''}
                        bgImageUrl={card?.cover.data?.attributes?.url ?? ''}
                        key={index}
                      />
                    </NormalizedLink>
                  </CarouselItem>
                ) : (
                  <CarouselItem
                    key={index}
                    className={cn('basis-auto pl-4', index === 0 && 'pl-0')}
                  >
                    <TeaserCard
                      title={card?.title ?? ''}
                      description={card?.description ?? ''}
                      bgImageUrl={card?.cover.data?.attributes?.url ?? ''}
                      key={index}
                    />
                  </CarouselItem>
                ),
              )}
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  )
}

function TeaserCard(props: {
  bgImageUrl: string
  title: string
  description: string
}) {
  return (
    <article className="relative flex h-80 w-60 flex-col justify-end overflow-hidden bg-global-dark-ink-blue-300">
      <Image
        src={props.bgImageUrl ?? ''}
        role="presentation"
        alt={props.title}
        className="teaser-card-mask absolute top-0 h-60 w-full"
      />

      <div className="relative flex h-full flex-col justify-end gap-2 px-6 py-10">
        <span className="font-sans text-uppercase-12 uppercase tracking-[6px] text-text-muted">
          {props.description}
        </span>
        <h3 className="font-display text-display-28 uppercase italic text-global-white ">
          {props.title}
        </h3>
      </div>
    </article>
  )
}

import { type RenderableTreeNodes } from '@markdoc/markdoc'
import { buttonVariants } from '@rouvydev/web-components/basics'
import { cn } from '@rouvydev/web-components/utils'
import { Cols } from '~/components/cols.tsx'
import { Image } from '~/components/image.tsx'
import { Markdown } from '~/components/markdown.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { type SimpleCtaFragment } from '~/model/api.ts'

type TransformedSimpleCta = {
  transformedDescription: RenderableTreeNodes
} & SimpleCtaFragment

export function prepareSimpleCtaProps(data: SimpleCtaFragment) {
  const transformedObject = data as TransformedSimpleCta

  return {
    id: data.id,
    title: data.simpleCtaTitle,
    button: data.simpleCtaButton,
    description: transformedObject.transformedDescription,
    anchor: data.simpleCtaAnchor,
    image: data.simpleCtaImage,
  }
}

type Props = ReturnType<typeof prepareSimpleCtaProps>

export function SimpleCta(props: Props) {
  const { title, description, image, button } = props

  return (
    <div id={props?.anchor ?? undefined}>
      <div className="py-20 md:py-24">
        <div className="container">
          <div className="md:gap-6">
            {image?.data?.attributes?.url && (
              <Cols size="10">
                <Image
                  src={image?.data?.attributes?.url}
                  alt={image?.data.attributes.alternativeText ?? ''}
                  width={image?.data.attributes.width ?? 500}
                  height={image?.data.attributes.height ?? 500}
                  formats={image?.data.attributes.formats}
                  className="mx-auto block"
                />
              </Cols>
            )}
            <Cols size="6">
              <div className="mt-[64px] flex flex-col items-center justify-center gap-6 text-center">
                <h3 className="font-display text-body-32 font-extrabold uppercase italic text-text-default md:text-display-40 xl:text-display-48">
                  {title}
                </h3>
                <div className="block-description">
                  <Markdown content={description} />
                </div>
                <p>
                  <NormalizedLink
                    to={button.link}
                    className={cn(
                      buttonVariants({
                        variant: button.buttonStyle ?? 'primary',
                      }),
                      button.buttonStyle,
                    )}
                  >
                    {button.text}
                  </NormalizedLink>
                </p>
              </div>
            </Cols>
          </div>
        </div>
      </div>
    </div>
  )
}

import { buttonVariants } from '@rouvydev/web-components/basics'
import { SubtitledContentBlockWithCta } from '@rouvydev/web-components/cms-blocks'
import { ProductsComparison as ProductsComparisonBlock } from '@rouvydev/web-components/plans'
import { cn } from '@rouvydev/web-components/utils'
import { Cols } from '~/components/cols.tsx'
import { NormalizedLink } from '~/components/normalized-link.tsx'
import { type ProductsComparisonFragment } from '~/model/api.ts'

export function prepareProductsComparisonProps(
  data: ProductsComparisonFragment,
) {
  return {
    id: data.id,
    title: data.productsComparisonTitle,
    subtitle: data.productsComparisonSubtitle,
    featuresTitle: data.productsComparisonFeaturesTitle,
    productsJson: data.productsJson,
    anchor: data.productsComparisonAnchor,
    button: data.button,
  }
}

type Props = ReturnType<typeof prepareProductsComparisonProps>

type Item = {
  name: string
  features: Record<string, any>
}

export function ProductsComparison(props: Props) {
  const { title, subtitle, productsJson, featuresTitle, button } = props

  let items: Item[] = []
  let features: string[] | undefined

  if (Array.isArray(productsJson)) {
    items = productsJson as Item[]
  } else if (
    !!productsJson &&
    'features' in productsJson &&
    'items' in productsJson
  ) {
    features = productsJson.features as string[]
    items = productsJson.items as Item[]
  }

  return (
    <div id={props?.anchor ?? undefined}>
      <div className="container">
        <Cols size="8">
          <SubtitledContentBlockWithCta
            title={title}
            subtitle={subtitle ?? undefined}
            cta={
              button && (
                <NormalizedLink
                  to={button.link}
                  className={cn(
                    buttonVariants({
                      variant: button.buttonStyle ?? 'primary',
                    }),
                    button.buttonStyle,
                  )}
                >
                  {button.text}
                </NormalizedLink>
              )
            }
          >
            <ProductsComparisonBlock
              featuresTitle={featuresTitle ?? ''}
              products={items}
              features={features}
            />
          </SubtitledContentBlockWithCta>
        </Cols>
      </div>
    </div>
  )
}
